@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Ploni';
  src: url('./assets/fonts/PloniRegular.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Ploni';
  src: url('./assets/fonts/PloniMedium.otf') format('truetype');
  font-weight: 500; /* Medium weight */
  font-style: normal;
}

@font-face {
  font-family: 'Ploni';
  src: url('./assets/fonts/PloniBold.otf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Ploni';
  src: url('./assets/fonts/PloniLight.otf') format('truetype');
  font-weight: 300; /* Light weight */
  font-style: normal;
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
  }
}

@layer base {
  * {
    @apply border-border;
    /* letter-spacing: 0.15rem; */
    font-family: Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-size: 16px;
  }
  body {
    @apply bg-background text-foreground;
    @apply h-screen;
  }
}

.scrollbarStyled {
  scrollbar-width: thin;
  scrollbar-color: gray; /* thumb and track color */
}

/* Works on Chrome, Edge, and Safari */
.scrollbarStyled ::-webkit-scrollbar {
  width: 8px; /* width of the entire scrollbar */
  height: 5px;
}

.scrollbarStyled ::-webkit-scrollbar-track {
  background: white; /* color of the tracking area */
}

.scrollbarStyled ::-webkit-scrollbar-thumb {
  background-color: gray; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 1px solid gray; /* creates padding around scroll thumb */
}
